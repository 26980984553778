@font-face {
  font-family: "Xilla";
  src: url("./static/fonts/xilla/Xilla-Regular.woff2") format("woff2"),
    url("./static/fonts/xilla/Xilla-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Xilla";
  src: url("./static/fonts/xilla/Xilla-Italic.woff2") format("woff2"),
    url("./static/fonts/xilla/Xilla-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Xilla";
  src: url("./static/fonts/xilla/Xilla-Bold-Italic.woff2") format("woff2"),
    url("./static/fonts/xilla/Xilla-Bold-Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Xilla";
  src: url("./static/fonts/xilla/Xilla-Bold.woff2") format("woff2"),
    url("./static/fonts/xilla/Xilla-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style-type: none;
}

:root {
  --brown: #58410a;
  --red: #ea2b1f;
  --honey: #edae49;
  --egg: #f9df74;
  --cream: #f9edcc;
  --white: #fff;
  --main-font: "Xilla", serif;
}

html {
  font-size: 24px;
}

body {
  background: var(--brown);
  min-height: 100vh;
  font-family: "Xilla", serif;
  color: var(--brown);
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
