@font-face {
  font-family: "Xilla";
  src: url(/static/media/Xilla-Regular.9416ec94.woff2) format("woff2"),
    url(/static/media/Xilla-Regular.09c4488f.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Xilla";
  src: url(/static/media/Xilla-Italic.d87fcb19.woff2) format("woff2"),
    url(/static/media/Xilla-Italic.acdec43b.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Xilla";
  src: url(/static/media/Xilla-Bold-Italic.6b49b9ae.woff2) format("woff2"),
    url(/static/media/Xilla-Bold-Italic.133a0736.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Xilla";
  src: url(/static/media/Xilla-Bold.a78d0303.woff2) format("woff2"),
    url(/static/media/Xilla-Bold.2a5a0204.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style-type: none;
}

:root {
  --brown: #58410a;
  --red: #ea2b1f;
  --honey: #edae49;
  --egg: #f9df74;
  --cream: #f9edcc;
  --white: #fff;
  --main-font: "Xilla", serif;
}

html {
  font-size: 24px;
}

body {
  background: #58410a;
  background: var(--brown);
  min-height: 100vh;
  font-family: "Xilla", serif;
  color: #58410a;
  color: var(--brown);
}

#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100vh;
  width: 100%;
}

